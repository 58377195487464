<template>
  <div class="home">
    <div class="wrap_header">
      <div class="user_info">
        <div class="name">
          <span>{{ userInfo.department }} {{ userInfo.name }}</span>
          |
          <span @click="logout">退出</span>
        </div>
        <div class="dateTime">
          {{ dateTime.now }}
          <label v-if="inhospital">
            <el-switch
              v-model="autoNav"
              activeColor="#13ce66"
              inactiveColor="#ff4949"
              @change="setAutoNav"
            />
            自动轮播
          </label>
          <label>
            <el-switch
              v-model="inhospital"
              activeColor="#13ce66"
              inactiveColor="#ff4949"
              @change="setInhospital"
            />
            院内模式
          </label>
          <el-dialog
            v-model="dialogVisible"
            width="30%"
          >
            <el-input
              v-model="password"
              type="password"
              placeholder="请输入解锁密码"
              @keyup.enter="checkPassword"
            />
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="cancelDialog">取消</el-button>
                <el-button
                  type="primary"
                  @click="checkPassword"
                  :disabled="password.length<8"
                >确认</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
      <div
        class="logo"
        :style="logoBg"
      />
      <div class="title" />
    </div>
    <div class="wrap_body">
      <div
        class="wrap_nav"
        v-if="inhospital"
      >
        <div
          :class="{ item: true, active: item.name === active }"
          v-for="item in nav"
          :key="item.name"
          @click="routerTo(item.url)"
        >
          <span :class="['iconfont', item.icon]" />{{ item.label }}
        </div>
      </div>
      <div class="wrap_contant">
        <router-view
          :totalData="totalData"
          :userInfo="userInfo"
          :inhospital="inhospital"
          v-if="totalData.stateChart.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
import json from './json'
import systemConfig from './systemConfig'
export default {
  name: 'IndexAA',
  data: () => {
    return {
      logo: systemConfig.logo,
      userInfo: json.userInfo,
      dateTime: {
        now: '',
        timeInterval: () => { return }
      },
      inhospital: !!sessionStorage.inhospital,
      totalData: {
        equipmentData: [],
        stateData: [],
        stateChart: [],
        timeInterval: () => { return }
      },
      nav: [],
      active: '',
      autoNav: true,
      navTimeInterval: () => { return },
      dialogVisible: false,
      password: '',
      option: {}
    }
  },
  computed: {
    logoBg () {
      if (this.logo) return `background-image: url('${this.logo}')`
      return ''
    }
  },
  watch: {
    active: function () {
      this.switchNav()
    },
  },
  async created () {
    window.Y = this
    this.nav = JSON.parse(JSON.stringify(systemConfig.nav))
    this.option = this.$route.query
    this.option.autoNav = this.autoNav = this.option.autoNav === 'true' && this.inhospital

    this.dateTime.timeInterval = setInterval(this.getDateTime, 1 * 1000)
    this.totalData.timeInterval = setInterval(await this.getTotal, 5 * 1000)
    if (this.option.demo !== 'Y') this.userInfo = JSON.parse(localStorage.userInfo || '{}')

    this.active = this.$route.path.split('/').slice(-1).join() || this.nav[0].name

    await this.getTotal()
  },
  mounted () {
    window.addEventListener('resize', function () {
      location.reload(0)
    })
  },
  beforeUnmount () {
    this.clearAll()
  },
  methods: {
    async getTotal () {
      if (!this.inhospital) this.routerTo(this.nav[0].url)
      let deviceOnlineNum = Math.abs(1324 + json.setRandom())
      let res = json.queryStudioDeviceTotalAndOnlineNumAndMemberTotal
      let deviceTotal = res.data.deviceTotal
      res.data.deviceFreeNum = deviceTotal - deviceOnlineNum
      res.data.deviceOnlineNum = deviceOnlineNum

      if (this.option.demo !== 'Y') {
        res = await this.$root.libs.request(
          this.$root.libs.api.ssyypt.training_recipe_running_web.queryStudioDeviceTotalAndOnlineNumAndMemberTotal,
          { studioid: this.userInfo.id, jwt: this.userInfo.jwt },
          { headers: { jwt: this.userInfo.jwt } }
        )
      }
      let data = [
        {
          name: '用户',
          value: res.data.studioMemberNum,
          equipmentInfo: 'Y',
          stateInfo: 'Y'
        },
        {
          name: '总设备',
          value: res.data.deviceTotal,
          equipmentInfo: 'Y',
          stateInfo: 'Y'
        },
        {
          name: '正在治疗',
          value: res.data.deviceOnlineNum,
          equipmentInfo: 'Y',
          stateChart: 'Y'
        },
        {
          name: '空闲设备',
          value: res.data.deviceFreeNum,
          equipmentInfo: 'Y',
          stateChart: 'Y'
        },
        {
          value: res.data.deviceOnlineNum,
          name: '在线设备',
          equipmentChart: 'Y',
          default: 'Y',
          stateChart: 'Y'
        },
        {
          value: res.data.deviceFreeNum,
          name: '离线设备',
          equipmentChart: 'Y',
          default: 'N'
        }
      ]
      this.totalData.equipmentData = data.filter(item => item.equipmentInfo === 'Y')
      this.totalData.equipmentChart = data.filter(item => item.equipmentChart === 'Y')
      this.totalData.stateData = data.filter(item => item.stateInfo === 'Y')
      this.totalData.stateChart = data.filter(item => item.stateChart === 'Y')
    },
    getDateTime () {
      this.dateTime.now = this.$root.libs.data.dateNow()
    },
    setAutoNav (val) {
      // 同步自动切换状态
      this.autoNav = this.option.autoNav = val
      this.switchNav()
    },
    switchNav () {
      clearInterval(this.navTimeInterval)
      if (!this.userInfo.jwt) return this.logout()
      if (!this.inhospital) return this.routerTo(this.nav[0].url)
      if (!this.option.autoNav || !this.autoNav) return
      const nav = this.nav
      const index = nav.findIndex(item => item.name === this.active)
      let nextIndex = index + 1 === nav.length ? 0 : index + 1
      let url = nav[nextIndex].url
      let time = nav[nextIndex].time
      this.navTimeInterval = setTimeout(() => {
        if (!this.option.autoNav) return
        this.routerTo(url)
      }, time)
    },
    async routerTo (path) {
      this.active = path
      this.$router.push({
        path,
        query: { ...this.option }
      })
      if (path !== 'class') this.$root.libs.data.setSession('isClear', 'N')
    },
    setInhospital (val) {
      this.inhospital = !val
      // 临时切换状态
      this.autoNav = false
      clearInterval(this.navTimeInterval)
      this.dialogVisible = true
      this.password = ''
    },
    cancelDialog () {
      // 还原自动切换状态
      this.autoNav = this.option.autoNav
      this.dialogVisible = false
      this.switchNav()
    },
    checkPassword () {
      let pass = this.password === 'shanshan'
      if (!pass) return this.$message.error('密码错误！')
      this.dialogVisible = false
      this.inhospital = !this.inhospital
      this.cancelDialog()

      // this.nav = systemConfig.nav
      if (!this.inhospital) return sessionStorage.removeItem('inhospital')

      this.$root.libs.data.setSession('inhospital', this.inhospital)
      // this.nav = [systemConfig.nav[0]]
      this.setAutoNav(false)
      this.routerTo(systemConfig.nav[0].url)
    },
    logout () {
      this.clearAll()
      this.$root.libs.data.setStorage('userInfo', '')
      console.log('退出登录')
      window.location.href = location.origin + location.pathname + '#/login'
      // this.routerTo('/login')
    },
    clearAll () {
      clearInterval(this.dateTime.timeInterval)
      clearInterval(this.totalData.timeInterval)
      clearInterval(this.navTimeInterval)
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: url("~@/assets/images/bg.png") center/cover;
  color: #fff;
}
.wrap_header {
  width: 100%;
  height: 5rem;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background: url("~@/assets/images/head_bg.png") center/cover;
  .user_info {
    height: inherit;
    padding-top: 1.25rem;
    float: right;
    box-sizing: border-box;
    text-align: right;
    line-height: 1.5;
    .name {
      color: var(--color-tips);
    }
  }
  .logo {
    width: 11.75rem;
    height: inherit;
    float: left;
    background: url("~@/assets/images/logo.png") no-repeat center/100% auto;
  }
  .title {
    width: 18.125rem;
    height: inherit;
    margin: auto;
    background: url("~@/assets/images/title.png") no-repeat center/100% auto;
  }
}
.wrap_body {
  height: calc(100vh - 5rem);
  display: flex;
  flex: 1;
  .wrap_nav {
    // width: 18.75rem;
    flex: 1;
    box-sizing: border-box;
    background: #092976;
    .iconfont {
      margin-right: var(--space-normal);
      font-size: var(--font-h2);
    }
    .item {
      height: 4.375rem;
      padding-left: 3rem;
      font-size: var(--font-h3);
      line-height: 4.375rem;
      border-left: 3px solid transparent;
      &.active {
        background: rgba(0, 0, 0, 0.3);
        color: #37f;
        border-color: #37f;
      }
    }
  }
  .wrap_contant {
    display: flex;
    flex: 7;
    margin: 2rem;
  }
}
</style>
<style lang="scss">
.wrap_contant {
  .wrap_box {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: var(--border-radius);
  }
}
</style>
